@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-Maigre-300.woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-MaigreItalique-300.woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-Normal-400.woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-Italique-400.woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-Demi-500.woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-DemiItalique-500.woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-Gras-700.woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Hydro-Quebec";
    src: url('../assets/fonts/Hydro-Quebec-GrasItalique-700.woff2');
    font-weight: 700;
    font-style: italic;
}