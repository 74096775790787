$listview-item-padding-y: 21px;
$listview-item-padding-x: 31px !important;
$listview-item-focus-bg: #f5f5f9;

@use '../../../components/base.scss' as *;

.k-listview {
  max-width: 400px;
  min-height: 400px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  z-index: 10;
  height: 100vh;

  .mobile & {
    max-width: none;
    height: calc(
      100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom))
    );
    // max-width: calc(100%);
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .k-listview-header {
    padding: 36px 34px 21px;

    .mobile & {
      height: 100px;
      padding: 20px;
      display: flex;
      gap: 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
      z-index: 999999;

      .btn-add {
        position: fixed;
        bottom: 30px;
        right: 30px;
        height: 60px;
        width: 60px;
        padding: 0 !important;
        z-index: 999999;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        svg {
          margin-left: 0;
        }
        .k-icon {
          display: none;
        }
      }
    }

    &.fixed {
      width: 398px;
      height: 181px;
      background-color: white;
      z-index: 10;
    }

    .k-listview-header-title {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      height: 61px;
      display: flex;
      align-items: center;
      margin-bottom: 7px;
    }
    .k-listview-header-content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &.no-scroll {
    height: auto;
    .k-listview-content {
      overflow-y: hidden;
    }
  }

  &.fixed {
    .k-listview-content {
      // top: 180px;
    }
  }

  .k-listview-content {
    overflow-y: scroll;
    height: calc(100vh);
    max-height: calc(100vh);

    .mobile & {
      // fix IOS mobile -- Was approved
      height: calc(100vh - 200px);
      max-height: calc(100vh - 200px);
    }
  }
}

.k-listview-item {
  display: grid;
  flex-direction: column;
  grid-template-areas: 'content action';
  grid-template-columns: 1fr 40px;
  gap: 8px;
  line-height: 1;
  cursor: pointer;
  border-top-color: rgba(0, 0, 0, 0.08) !important;
  &:nth-child(odd) {
    background-color: var(--row-color);
  }

  &::after {
    content: url('./assets/arrow.svg');
    width: 40px;
    height: 40px;
    border-radius: 100%;
    grid-area: action;
    background-color: var(--primary);
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 250ms ease-in-out;
  }

  &.no-arrow {
    &::after {
      content: none;
    }
  }

  .k-listview-item-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &:hover,
  &.selected {
    background-color: $listview-item-focus-bg;
    .k-listview-item-title {
      font-weight: 700;
      color: var(--primary);
    }
    &::after {
      opacity: 1;
    }
  }

  .k-listview-item-footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
