@use "./font.scss" as *;

$card-border-radius: 0;
$font-family: "Hydro-Quebec", sans-serif;
$font-size: 14px !default;
$font-size-xs: 10px !default;
$font-size-sm: 12px !default;
$font-size-md: 14px !default;
$font-size-lg: 24px !default;
$font-size-xl: 20px !default;

$black: #000000;
$white: #ffffff;

$primary: #0f096c;
$input-color: #104cc3;
$body-bg: #f6f5f9 !default;
$component-bg: $white;
$body-text: #424242 !default;
$card-header-text: #262626 !default;
$heading-text: #0f096c !default;
$subtle-text: #666666 !default;
$disabled-text: #8f8f8f !default;
$line-color: #ef5500;
$row-color: #fafafa;
$button-danger-color: #d41b25;
$button-orange: #ff9b00;
$separator-color: #c5cdd6;
$panel-color: #f5f5f9;
$green-color: #2d9d78;

$app-bg: try-shade($body-bg, 0.25) !default;
$app-text: $body-text !default;
$app-border: rgba($black, 0.08) !default;
$button-padding-y: 16px;
$button-padding-x: 32px;
$button-border-radius: 72px;
$tabstrip-content-padding-y: 34px;
$tabstrip-content-padding-x: 32px;
$tabstrip-content-border-width: 0;
// $tabstrip-content-text
$tabstrip-content-bg: $white;
$tabstrip-content-border: 0;

// CHART OVERRIDES
$series-1: #095aba;
$series-2: $line-color;

:root {
	--font-size: #{$font-size};
	--font-size-xs: #{$font-size-xs};
	--font-size-sm: #{$font-size-sm};
	--font-size-md: #{$font-size-md};
	--font-size-lg: #{$font-size-lg};
	--font-size-xl: #{$font-size-xl};
	--font-family: #{$font-family};

	--black: #{$black};
	--white: #{$white};

	--primary: #{$primary};
	--body-bg: #{$body-bg};
	--component-bg: #{$white};
	--body-text: #{$body-text};
	--input-color: #{$input-color};
	--row-color: #{$row-color};
	--button-danger-color: #{$button-danger-color};
	--button-orange: #{$button-orange};
	--card-header-color: #{$card-header-text};
	--separator-color: #{$separator-color};
	--panel-color: #{$panel-color};
	--green-color: #{$green-color};

	--heading-text: #{$heading-text};
	--subtle-text: #{$subtle-text};
	--disabled-text: #{$disabled-text};

	--app-bg: #{$app-bg};
	--app-text: #{$app-text};
	--app-border: #{$app-border};
	--button-padding-y: #{$button-padding-y};
	--button-padding-x: #{$button-padding-x};
	--button-border-radius: #{$button-border-radius};

	--line-color: #{$line-color};
}

// button
.k-button {
	.k-icon {
		order: 1;
	}

	&.k-primary {
		background-color: var(--primary);
		color: white;
	}

	&.k-flat {
		color: var(--primary);
	}

	&.k-small {
		padding: 8px var(--button-padding-x);
	}

	&.with-margin-top {
		margin-top: 2rem;
	}

	&.k-delete-button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin: 0;
		color: var(--button-danger-color);

		svg {
			margin: 0 !important;
		}

		&:hover {
			color: white;
			background-color: var(--button-danger-color);
			border: 1px solid var(--button-danger-color);
		}
	}

	&.k-orange {
		background: var(--button-orange);
		color: #080238;
		border: none;
	}

	&.k-outline {
		&:hover {
			svg {
				color: white;
			}
		}
	}

	svg {
		margin-left: 1rem;
	}
}
