$grid-header-bg: #fff;
$grid-bg: #fff;
$grid-header-text: #262626;
$grid-border-width:0;
$grid-border: #E5E5E5;
$grid-cell-vertical-border-width:0;
$grid-cell-horizontal-border-width:1px;
$grid-alt-bg: #fff;
$grid-sorted-bg:#fff;
$grid-hovered-bg:#F5F5F9;

.k-grid{
    border-bottom:$grid-cell-horizontal-border-width solid $grid-border !important;
    position: unset!important;

    table{
        border: none;
    }

    div[role="presentation"]{
        position: unset!important;
    }


}
.k-grid-header{
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 20px;
}
.k-grid-content{
    overflow-x: visible!important;
    overflow-y: visible!important;
}
.k-grid-header-wrap,
.k-grid-footer-wrap{
    border:none!important;
}
.k-master-row{
    height: 50px;
}
.k-hierarchy-cell{
    .k-icon{
        color: #104CCE!important;

        &.k-i-plus::before{
            content: url('../assets/svg/arrow-down.svg');
            height: 7px;
            width: 12px;
        }
    }
}

.k-grid .k-detail-row .k-detail-cell{
    padding: 50px 12px;
}