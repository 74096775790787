.grid {
  display: grid;
  gap: 24px 16px;
  &.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 502px));
  }
  &.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 335px));
  }
}

.w-full {
  grid-column: 1 / -1;
}

.associate-vehicle-form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 24px 16px;
  gap: 24px 16px;
  width: 100%;
  grid-column: span 6;

  .k-form-field,
  .button-container {
    grid-column: span 6;
  }

  .button-container {
    > div {
      display: flex;
      align-items: center;
    }
  }
}
