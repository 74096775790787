@use "../base.scss" as *;

$input-text: #161616;
$input-border-radius: 10px;
$input-placeholder-font-size: 14px;
$input-font-size: 16px;
$input-line-height: 1.3;
$input-padding-y: 16.5px;
$input-padding-x: 23px;
$input-border: rgb(197, 205, 214);
$combobox-select-gradient: rgba(black, 0), rgba(black, 0);
$combobox-select-bg: #fff;
$combobox-select-hovered-bg: #fff;
$combobox-select-pressed-bg: #fff;
$multiselect-border-color: #d6d6d6;
$placeholder-color: #757575;
$vehicle-combobox-height: 90px;

input {
	font-weight: 700;
}
.k-textbox,
.k-textarea,
.k-multiselect .k-multiselect-wrap,
.k-combobox .k-dropdown-wrap,
.k-combobox {
	border-top: none;

	&:hover,
	&:focus {
		border-color: $input-border;
		box-shadow: 0 2px 4px 0px rgba(197, 205, 214, 0.5);
	}
}
.k-dropdown {
	.k-dropdown-wrap {
		background: white !important;

		.k-select {
			&:hover {
				background: rgba(var(--input-color), 0.04) !important;
				border-radius: 0 4px 4px 0;
			}
		}
	}
}

.k-combobox {
	&.k-vehicle-combobox {
		min-height: $vehicle-combobox-height;

		.k-empty-box {
			width: 100%;
			background: white;
			border: none;
			border-radius: $input-border-radius;

			&:hover {
				cursor: pointer;
			}
		}

		.k-select {
			display: none;
		}

		.k-clear-value {
			height: 100%;
		}
	}
}

.k-list,
.k-popup,
.k-list-scroller,
.k-animation-container {
	border-radius: $input-border-radius;
}

.k-label {
	z-index: 1;
	position: relative;
	transform: translate(0, 7px) !important;
	left: 0 !important;
	top: 0 !important;
	color: #606c7a;
	filter: drop-shadow(0 0 8px #fff);
	font-size: 12px !important;
	font-weight: 700;
	position: relative !important;
	display: flex;
	gap: 5px;
	flex-wrap: nowrap;
	padding: 0 !important;
	width: 100% !important;
	max-width: none !important;
	white-space: nowrap;
	overflow-x: clip;
	&::after {
		content: "";
		flex-grow: 2;
		border-radius: 0 $input-border-radius 0 0;
		border-color: $input-border;
		border-width: 1px;
		border-style: solid;
		border-bottom: none;
		border-left: none;
		border-right: none;
		transform: translate(0, 10px);
	}
	&::before {
		content: "";
		width: 20px;
		border-radius: $input-border-radius 0 0;
		border-color: $input-border;
		border-width: 1px;
		border-style: solid;
		border-bottom: none;
		border-left: none;
		border-right: none;
		transform: translate(0, 10px);
	}
}

.k-select {
	background: transparent !important;

	.k-link {
		&:hover {
			background: rgba(var(--input-color), 0.04) !important;
			border-radius: 0 4px 4px 0;
		}
	}
}

.k-select .k-icon.k-i-arrow-n::before {
	content: "";
	width: 9px;
	height: 9px;
	transform: rotate(45deg);
	transform-origin: 50% 50%;
	border: var(--input-color) 2px solid;
	border-bottom: none;
	border-right: none;
	transition: all 250ms ease-in-out;
}

.k-select .k-icon.k-i-arrow-s::before {
	content: "";
	width: 9px;
	height: 9px;
	transform: rotate(45deg);
	transform-origin: 50% 50%;
	border: var(--input-color) 2px solid;
	border-top: none;
	border-left: none;
	transition: all 250ms ease-in-out;
}
.k-form {
	> * {
		&:first-child {
			margin-top: 1rem;
		}
	}

	.k-input::placeholder,
	.k-textbox::placeholder {
		color: $placeholder-color;
		font-size: $input-placeholder-font-size;
		font-weight: normal;
		font-style: italic;
	}

	.k-form-field {
		&.no-label {
			padding-top: 1rem;
		}
	}

	.k-textbox-container {
		flex-direction: column-reverse;
		padding: 0 !important;
		margin-top: 1rem;

		input {
			margin-top: 2px;
		}
	}

	.k-multiselect {
		.k-reset {
			padding: 0 0.5rem;
			.k-button {
				background: var(--row-color);
				border: 1px solid $multiselect-border-color;
				box-sizing: border-box;
				border-radius: 4px;
				margin: 0.75rem 0 0.75rem 0.5rem;
			}
		}
	}

	.k-datepicker,
	.k-timepicker,
	.k-datetimepicker {
		.k-dateinput-wrap {
			border-radius: 10px !important;
		}

		.k-select,
		.k-link,
		.k-link-date {
			border: none;
			background: transparent;
			position: absolute;
			right: 0;
			height: 100%;

			.k-icon,
			.k-i-calendar {
				color: var(--input-color);
			}

			&:hover {
				background: rgba(var(--input-color), 0.04);
			}
		}
	}
}

.button-wrapper {
	margin: 2rem 0;
	width: 100%;
	display: flex;

	:first-child.k-button {
		margin-left: auto;
	}

	:not(:last-child).k-button {
		margin-right: 1rem;
	}
}

.k-datetime-container {
	z-index: 10002 !important;
}

.k-form > *:not(.k-hidden):first-child,
.k-form > *.k-hidden + :not(.k-hidden) {
	margin-top: 14px;
}
