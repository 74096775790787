@use 'base.scss' as *;
@use 'k-grid.scss' as *;
@use 'menuBar/index.scss' as *;
@use '../layout/base/ListPanel/styles.scss' as *;
@use 'forms/styles.scss' as *;
@use './layout.scss' as *;
@use './k-scheduler.scss' as *;
@use 'dialog/styles.scss' as *;
@use './auth.scss' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* -------------------------------------------------------------------
      Assign the default/constant/env values to CSS variables
  */
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;

  @supports (top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }

  --safeArea: calc(
    100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom))
  );
}

@import '~@progress/kendo-theme-default/dist/all.scss';

html {
  height: 100%;
  min-height: -webkit-fill-available;
  // height: var(--safeArea);

  // outline: blue solid 1px;
}
body {
  font-family: $font-family;
  height: 100%;
  min-height: -webkit-fill-available;
  margin: 0 auto;
  padding: var(--safe-area-inset-top) var(--safe-area-inset-right)
    var(--safe-area-inset-bottom) var(--safe-area-inset-left);

  // overflow: hidden;
  // max-height: var(--safeArea);
  // height: var(--safeArea);

  > iframe {
    pointer-events: none;
    display: none;
  }
}

* {
}

#root {
  height: 100%;
  // height: var(--safeArea);
  // max-height: var(--safeArea);
  width: 100%;
  display: block;
  // overflow: hidden;
  // border: red solid 1px;
}
