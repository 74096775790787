$custom-item-primary-accent: #095aba;
$header-height: 181px;
$sidebar-width: 507px;

.schedule {
  max-width: calc(100vw - $sidebar-width);
  min-height: 100vh;

  &.mobile-scheduler {
    width: 1024px;
    max-width: 2000px;
    border: none;

    .k-scheduler-row {
      border-color: rgba(0, 0, 0, 0.08);
    }

    .k-scheduler-body {
      .k-side-cell {
        background: white !important;
      }
      // top: $header-height;
      > .k-scheduler-group {
        > .k-scheduler-row {
          > .k-scheduler-cell.k-group-content {
            > .k-scheduler-row:nth-child(odd) {
              background-color: var(--row-color);
            }
          }
        }
      }
    }
  }

  .k-current-time {
    z-index: 10;
    background: $custom-item-primary-accent;

    &.k-current-time-arrow-down {
      border-color: $custom-item-primary-accent;
      border-radius: 50%;
      bottom: 0;
    }
  }

  .k-scheduler-head {
    height: calc($header-height / 2);
    .k-scheduler-cell.k-group-content {
      .k-scheduler-row {
        & > div {
          align-items: center;
        }
      }
    }
  }

  .k-scheduler-timeline-view {
    .k-scheduler-head {
      .k-scheduler-cell.k-group-content {
        .k-scheduler-row {
          &:first-child {
            display: none;
          }
        }
      }
    }

    .k-sticky-cell {
      display: none !important;
    }

    .k-scheduler-body {
      // top: $header-height;
      > .k-scheduler-group {
        > .k-scheduler-row {
          > .k-scheduler-cell.k-group-content {
            > .k-scheduler-row:nth-child(odd) {
              background-color: var(--row-color);
            }
          }
        }
      }

      .k-scheduler-cell {
        &.k-resource-row {
          height: 109px;

          .k-slot-cell:nth-child(odd) {
            border-right: none;
          }
        }
      }
    }

    .k-dialog {
      height: 665px !important;

      &.k-window {
        border-radius: 4px;
      }

      .k-header {
        border-color: transparent;
        border-bottom: #e5e5e5;
      }

      .k-dialog-content {
        &.k-window-content {
          &:last-child {
            padding: 0 32px;
          }
        }

        .k-button-group {
          margin-top: 0.5rem;

          .k-button {
            width: 56px !important;
            height: 56px;
            padding: 0 !important;
            border-radius: 4px !important;

            &:not(:first-child) {
              margin-left: 1rem;
            }

            &:not(.k-state-selected) {
              background: transparent;

              &:hover {
                background: var(--primary);
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .k-scheduler-day-view {
    .k-sticky-cell {
      display: none !important;
    }

    .k-scheduler-body {
      > .k-scheduler-group {
        > .k-scheduler-row {
          > .k-heading-cell {
            display: none !important;
          }

          > .k-group-content {
            .k-scheduler-row:first-child {
              display: none;
            }
          }
        }
      }
    }

    .k-scheduler-head {
      > .k-scheduler-group {
        > .k-scheduler-row {
          > .k-heading-cell {
            display: none !important;
          }

          > .k-group-content {
            > .k-scheduler-row {
              .k-scheduler-cell {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
