$large-padding: 100px;
$large-width: 580px;
$gap: 20px;

.auth-layout {
	display: grid;
	grid-template-columns: 1fr $large-width;
	grid-template-rows: 80px auto 64px;
	height: 100%;

	&__nav {
		display: flex;
		align-items: center;
		gap: $gap;
		padding: 0 $large-padding;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: $gap;
	}

	&__left-panel {
		padding: $large-padding;
		background-color: var(--primary);
		grid-row: 2 / 3;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;

		h2 {
			font-size: 48px;
			max-width: $large-width;
			margin-bottom: 60px;
		}

		svg {
			margin-bottom: 60px;
		}

		ul {
			max-width: $large-width;
			li {
				margin-bottom: 24px;
			}
		}
	}

	&__right-panel {
		background-color: var(--panel-color);
		padding: $large-padding;
		grid-row: 2 / 3;

		h2 {
			font-size: 28px;
			line-height: 36px;
			color: #262626;
		}

		h3 {
			font-size: 14px;
			line-height: 22px;
			color: var(--card-header-color);
			font-weight: 400;
		}

		.inline {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 32px 0;

			&.border-bottom {
				border-bottom: 1px solid var(--separator-color);
			}
		}

		.contact {
			display: flex;
			align-items: center;
			padding: 32px 0;
			.k-paragraph {
				margin: 0;
			}

			a {
				margin-left: 0.25rem;
			}
		}
	}

	&__copyright {
		display: flex;
		align-items: center;
		padding: 0 $large-padding;

		p {
			color: var(--card-header-color);
			font-size: 12px;
			line-height: 16px;
		}
	}

	&__terms {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		a {
			color: var(--card-header-color);
			font-size: 12px;
			line-height: 16px;
		}
	}
}

.mobile-layout-auth {
	background-color: var(--panel-color);
	min-height: 100vh;

	&__header {
		padding: 0 25px;
		height: 50px;
		background-color: var(--primary);
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__content {
		padding: 50px 25px;
		h2 {
			font-size: 28px;
			line-height: 36px;
			color: #262626;
		}

		h3 {
			font-size: 14px;
			line-height: 22px;
			color: var(--card-header-color);
			font-weight: 400;
		}

		.inline {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 32px 0;

			&.border-bottom {
				border-bottom: 1px solid var(--separator-color);
			}
		}

		.contact {
			display: flex;
			align-items: center;
			padding: 32px 0;
			.k-paragraph {
				margin: 0;
			}
			a {
				margin-left: 0.25rem;
			}
		}
	}
}
